import React, { Component, Text } from "react";
import { List } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { setFsiToken } from 'actions/userSettings';
import { performFsiLogin } from 'actions/login';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as COOKIES from "constants/cookies";
import * as Cookies from "js-cookie";

let loggedIn = false;

class FSIScreen extends Component {
  render() {
    const searchParams = new URLSearchParams(window.location.search);
    if (!searchParams.has('t')) {
            return null;
    }

    const token = searchParams.get('t');
    console.log('Found token ' + token);
    
    if (!loggedIn && !!token) {
	    console.log('not logged in, performing fsi');
      Cookies.set(COOKIES.fsiToken, token);
      this.props.performFsiLogin().then(() => {
                loggedIn = true;
                console.log('logged in');
        });
    }

    return loggedIn == true ? (
	    <Text>{'...'}</Text>
    ) : (
      <Text>{'Set'}</Text> 
    );
  }
}

function mapStateToProps(state, props) {
  return state;
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      performFsiLogin,
	    setFsiToken,
    },
    dispatch
  );
}

export default withRouter( connect(
  mapStateToProps,
  matchDispatchToProps
)(FSIScreen));

