import { TYPES } from "actions/index";
import { request } from "network/request";
import * as firebase from "firebase/app";
import "firebase/auth";
import {
  firebaseApp,
  createFacebookCredential
} from "components/firebase/firebase";
import * as COOKIES from "constants/cookies";
import * as Cookies from "js-cookie";

export function login(token) {
  return dispatch => {
    const credential = createFacebookCredential(token);
    firebaseApp
      .auth()
      .signInAndRetrieveDataWithCredential(credential)
      .then(currentUser => {
        dispatch(_receiveFirebaseUser(currentUser));
      });

    request({
      method: "GET",
      url: "/api/login"
    }).then(res => {
      dispatch(_receiveProfile(res.data));
    });
  };
}

export function performFsiLogin() {
  return dispatch => {
    request({
      method: "GET",
      url: "/api/login",
      headers: {
        'Authorization': 'Bearer ' + Cookies.get(COOKIES.fsiToken),
      }
    }).then(res => {
      dispatch(_receiveProfile(res.data));
      return this;
    });
  };
}

export function logout(token) {
  return dispatch => {
    dispatch(_receiveFirebaseUser(null));
    dispatch(_receiveProfile(null));
  };
}

function _receiveFirebaseUser(user) {
  return {
    type: TYPES.RECEIVE_FIREBASE_USER,
    payload: user
  };
}

function _receiveProfile(profile) {
  return {
    type: TYPES.RECEIVE_PROFILE,
    payload: profile
  };
}
