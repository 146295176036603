import React, { Component } from "react";
import { Button } from "semantic-ui-react";
import axios from "axios";
import * as Cookies from "js-cookie";
import * as COOKIES from "constants/cookies"

class FacebookButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      FB: window.FB
    };
  }

  componentDidMount() {
    document.addEventListener(
      "FBObjectReady",
      this._initializeFacebookLogin.bind(this)
    );
    // this._initializeFacebookLogin();

    const authToken = Cookies.get(COOKIES.fsiToken);
    if (!authToken) {
      return;
    }

    axios.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
    this.props.onLogin();
  }

  componentWillUnmount() {
    document.removeEventListener(
      "FBObjectReady",
      this._initializeFacebookLogin.bind(this)
    );
  }

  render() {
    if (!this.state.FB) {
      return null;
    }

    if (this.props.isLoggedIn) {
      return (
        <Button
          color="facebook"
          icon="facebook"
          content="Log out"
          onClick={this._logOutTapped.bind(this)}
        />
      );
    } else {
      return (
        <Button
          color="facebook"
          icon="facebook"
          content="Log in with Facebook"
          onClick={this._logInTapped.bind(this)}
        />
      );
    }
  }

  _logInTapped() {
    if (!this.state.FB) {
      return;
    }

    this.state.FB.login(this._facebookLoginHandler.bind(this), {
      scope: "email"
    });
  }

  _logOutTapped() {
    if (!this.state.FB) {
      return;
    }

    this.state.FB.logout(this._facebookLoginHandler.bind(this));
  }

  /**
   * Init FB object and check Facebook Login status
   */
  _initializeFacebookLogin() {
    const FB = window.FB;
    this.setState({
      FB: FB
    });

    if (FB) {
      FB.getLoginStatus(this._facebookLoginHandler.bind(this));
    }
  }

  _facebookLoginHandler(response) {
    const FB = this.state.FB;
    const authToken = Cookies.fsiToken;
    axios.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;

    if (authToken) {
      this.props.onLogin(authToken);
    } else {
      this.props.onLogout();
    }
  }
}

export default FacebookButton;
