import React, { Component } from "react";
import { Container, Menu, Dropdown, Image } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import appIcon from "images/bm-Icon.jpg";
import { Link } from "react-router-dom";
import "components/main/AppHeader.css";
import FacebookButton from "components/buttons/FacebookButton";
import { login, logout, performFsiLogin } from "actions/login";
import {
  languages,
  languageCodeForLanguage,
  languageForLanguageCode
} from "constants/languages";
import { capitalizeFirstLetter } from "util/stringUtil";
import { changeLanguage } from "actions/userSettings";
import { fetchAllNonLyrics } from "actions/nodes";

class AppHeader extends Component {
  componentDidMount() {
    if (
      !this.props.graph ||
      !this.props.graph.nodes ||
      !this.props.graph.nodeTypes
    ) {
      this.props.fetchAllNonLyrics();
    }
  }

  render() {
    const canCreate =
      this.props.auth &&
      this.props.auth.apiUser &&
      this.props.auth.apiUser.data &&
      this.props.auth.apiUser.data.permissions.includes("CREATE");
    const canUpdate =
      this.props.auth &&
      this.props.auth.apiUser &&
      this.props.auth.apiUser.data &&
      this.props.auth.apiUser.data.permissions.includes("UPDATE");

    const nodeTypes = this._getNodeTypes();

    return (
      <header className="App-header no-print">
        <Container>
          <Menu stackable inverted pointing>
            <Menu.Item header>
              <Link to={`/`}>
                <Image
                  size="mini"
                  src={appIcon}
                  style={{
                    marginLeft: 0,
                    marginRight: "1.5em"
                  }}
                />
              </Link>
              <Link to={`/`}>Bhajan Mala</Link>
            </Menu.Item>
            <Dropdown item text="Browse">
              <Dropdown.Menu>
                <Dropdown.Item>
                  <Link className={`dropdownMenuItem`} to={`/browse`}>
                    Bhajans
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link className={`dropdownMenuItem`} to={`/playlists`}>
                    Playlists
                  </Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown item text="Language">
              {this._renderLanguageSwitcher()}
            </Dropdown>

            {(canCreate || canUpdate) && (
              <Dropdown item text="Admin">
                <Dropdown.Menu>
                  {nodeTypes
                    .filter(
                      nodeType =>
                        nodeType.name === "ragam" ||
                        nodeType.name === "talam" ||
                        nodeType.name === "composer" ||
                        nodeType.name === "deity" || 
                        nodeType.name === "compositionLanguage"
                    )
                    .map(nodeType => this._renderListNodeType(nodeType))}
                  <Dropdown.Item>
                    <Link
                      className={`dropdownMenuItem`}
                      to={`/nodeTypes/1/nodes/0/edit`}
                    >
                      Add Bhajan
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link
                      className={`dropdownMenuItem`}
                      to={`/admin/consolidate`}
                    >
                      Consolidate Nodes
                    </Link>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}

            <Menu.Item position="right">
              <FacebookButton
                isLoggedIn={
                  this.props.auth &&
                  this.props.auth.apiUser &&
                  this.props.auth.apiUser.data &&
                  this.props.auth.apiUser.data.token &&
                  this.props.auth.firebaseUser
                }
                onLogin={this.props.performFsiLogin.bind(this)}
                onLogout={this.props.logout.bind(this)}
              />
            </Menu.Item>
          </Menu>
        </Container>
      </header>
    );
  }

  _renderLanguageSwitcher() {
    const selectedLanguageCode = this.props.userSettings.selectedViewLanguage;
    const selectedLanguage = languageForLanguageCode(selectedLanguageCode);
    const that = this;
    const onLanguageClicked = language => {
      return () => {
        that._onLanguageClicked(language);
      };
    };
    const options = languages.map(language => {
      return (
        <Dropdown.Item
          key={language}
          active={selectedLanguage === language}
          onClick={onLanguageClicked(language)}
        >
          {capitalizeFirstLetter(language)}
        </Dropdown.Item>
      );
    });
    return <Dropdown.Menu>{options}</Dropdown.Menu>;
  }

  _renderListNodeType(nodeType) {
    return (
      <Dropdown.Item key={nodeType.nodeTypeId}>
        <Link
          className={`dropdownMenuItem`}
          to={`/nodeTypes/${nodeType.nodeTypeId}`}
        >
          {nodeType.pluralName}
        </Link>
      </Dropdown.Item>
    );
  }

  _onLanguageClicked(language) {
    this.props.changeLanguage(languageCodeForLanguage(language));
  }

  _getNodeTypes() {
    if (
      !this.props.graph ||
      !this.props.graph.nodes ||
      !this.props.graph.nodeTypes
    ) {
      return [];
    }

    const nonListableNodeTypes = ["bhajans", "lyrics", "users"];
    return this.props.graph.nodeTypes.filter(
      nodeType => !nonListableNodeTypes.includes(nodeType.pluralName)
    );
  }
}

function mapStateToProps(state, props) {
  return state;
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      login,
      logout,
      performFsiLogin,
      changeLanguage,
      fetchAllNonLyrics
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(AppHeader);
