import { TYPES } from "actions/index";
import * as COOKIES from "constants/cookies";
import * as Cookies from "js-cookie";
import { fetchAllNonLyrics, clearFetchedNodes } from "actions/nodes";

export function changeLanguage(language) {
  return dispatch => {
    Cookies.set(COOKIES.selectedViewLanguage, language);
    dispatch(_receiveChangeLanguage(language));

    const clearFetchedNodesDispatcher = clearFetchedNodes();
    clearFetchedNodesDispatcher(dispatch);

    const fetchAllNonLyricsDispatcher = fetchAllNonLyrics();
    fetchAllNonLyricsDispatcher(dispatch);
  };
}

function _receiveChangeLanguage(language) {
  return {
    type: TYPES.LANGUAGE_CHANGED,
    payload: language
  };
}

export function setFsiToken(token) {
  return dispatch => {
    Cookies.set(COOKIES.fsiToken, token);
  };
}

